body {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: pan-y;
  overflow-x: hidden;
}

.button-sprite {
  background-position: top;
  background-size: cover;
  display: inline-block;
  position: absolute;
}

.button-sprite:active {
  background-position: bottom;
}
